


import Vue from 'vue';
import { mapState } from 'vuex';
import { TFile } from '@/_types/file.type';

interface IUploaderData {
  tempData: boolean;
};

interface IUploaderComputed {
  file: TFile;
  loading: boolean;
  uploadedPhotoVideo: TFile;
};

interface IUploaderMethods {
  onFileChange: (e: Event) => void;
  createFile: (file: File) => void;
  validateFile: (fileType: string, fileSizeRaw: number, validationMethodName: string) => boolean;
  setError: (errorTranslationKey: string) => void;
  rmErr: () => void;
};

type TUploaderProps = {
  maxFileSizeGeneral?: number;
  maxFileSizeImage?: number;
  maxFileSizeVideo?: number;
  eventNameToEmit?: string;
  validationMethodName?: string;
  clickOutside: boolean;
};

const Uploader = Vue.extend<IUploaderData, IUploaderMethods, IUploaderComputed, TUploaderProps>({
  name: 'uploader',
  components: {
  },
  computed: {
    ...mapState('uploadPromopageFilesStore', ['file', 'loading', 'uploadedPhotoVideo']),
  },
  props: {
    maxFileSizeGeneral: {
      type: Number,
      default: 300,
    },
    maxFileSizeImage: {
      type: Number,
      default: 10,
    },
    maxFileSizeVideo: {
      type: Number,
      default: 300,
    },
    eventNameToEmit: { // The event name can be provided. Seems to be a good idea
      type: String,
      default: 'uploadingOutcome'
    },
    validationMethodName: { // See a switch-case inside methods.validateFile
      type: String,
      default: 'general',
    },
    clickOutside: { // See watch/clickOutside
      type: Boolean,
      default: false
    }
  },
  watch: {
    /* Passing the click into the input using a prop and a watcher
     * because of otherwise (via a method in uploader.vue) TypeScript
     * will make life unnecessarily hard
     */
    clickOutside: {
      handler(): void {
        if(this.clickOutside === true) {
          this.$nextTick(() => { // nextTick so that Vue has time to actually insert the ref'd element
            (this.$refs.uploaderInput as HTMLInputElement).click();
            this.$nextTick(() => {
              this.$emit('clickOutsideSucceeded'); // Emit the event so that the parent can i.e. switch that prop to false
            });
          });
        }
      }
    }
  },
  data(): IUploaderData {
    return {
      tempData: true,
    };
  },
  methods: {

    /* Main file change handler
     */
    onFileChange(e: Event): void {
      const files: FileList | null = (e.target as HTMLInputElement).files || (e as DragEvent).dataTransfer.files;
      if (!files.length) {
        return;
      }

      this.createFile(files[0]);
    },

    /* Uploads a file into our cloud bucket
     */
    createFile(file: File): void {
      const reader = new FileReader();

      this.$emit('uploadingAttemptStarted');

      reader.onload = async (): Promise<void> => {

        if (this.validateFile(file.type, file.size, this.validationMethodName)) {
          this.$store.dispatch('cabinetLobbyStore/uploadFile', file).then((data: TFile) => {

            this.$emit('uploadingAttemptFinished');

            // Backend has time limit of N sec, after which it closes the connection
            if (!data || !data.url) {
              this.setError('errors.validation.connection_timed_out');
              return;
            }

            this.$emit(this.eventNameToEmit, data);
          }, () => {
            this.$emit('uploadingAttemptFinished');
          }).catch(() => {
            this.$emit('uploadingAttemptFinished');
          });
        } else {
          this.$emit('uploadingAttemptFinished');
        }

      };

      reader.readAsDataURL(file);

    },

    /* Some validation with a dictionary
     */
    validateFile(fileType: string, fileSizeRaw: number, validationMethodName: string): boolean {
      let isFile: string[] | string | void;
      if (fileType) {
        isFile = fileType.split('/');
      }

      switch (validationMethodName) {
        case 'image':
          if (!isFile || !isFile[0] || isFile[0] !== 'image') {
            this.setError('errors.validation.file_upload_failed');
            return false;
          } else if (fileSizeRaw > this.maxFileSizeImage * 1024 * 1024) {
            this.setError('errors.validation.file_size_too_large');
            return false;
          }
          this.rmErr();
          return true;
        case 'video':
          if (!isFile || !isFile[0] || isFile[0] !== 'video') {
            this.setError('errors.validation.file_upload_failed');
            return false;
          } else if (fileSizeRaw > this.maxFileSizeVideo * 1024 * 1024) {
            this.setError('errors.validation.file_size_too_large');
            return false;
          }
          this.rmErr();
          return true;
        case 'imageOrVideo':
          if (!isFile || !isFile[0] || ((isFile[0] !== 'image') && (isFile[0] !== 'video'))) {
            this.setError('errors.validation.file_upload_failed');
            return false;
          } else if (
            (isFile[0] === 'image' && fileSizeRaw > this.maxFileSizeImage * 1024 * 1024) ||
            (isFile[0] === 'video' && fileSizeRaw > this.maxFileSizeVideo * 1024 * 1024)
          ) {
            this.setError('errors.validation.file_size_too_large');
            return false;
          }
          this.rmErr();
          return true;
        default:
          if (fileSizeRaw > this.maxFileSizeGeneral * 1024 * 1024) { // approx 150 MB
            this.setError('errors.validation.file_size_too_large');
            return false;
          } else {
            this.rmErr();
            return true;
          }
      }

    },

    /* Sends error event to the parent component
     */
    setError(errorTranslationKey: string): void {
      this.$emit('uploadingError', { messageKey: errorTranslationKey, isError: true });
    },

    /* Sends remove error event to the parent component
     * Same event name ATM, different data
     */
    rmErr(): void {
      this.$emit('uploadingError', { messageKey: '', isError: false });
    },

  }
});
export default Uploader;
