


import Vue from 'vue';
import notesList from '@/views/components/promoPage/notes/notesList.vue';
import uploader from '@/_modules/promo/components/uploader/uploader.vue';
import { mapGetters, mapState, mapActions } from 'vuex';
import { TEvent } from '@/_types/event.type';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import { TContact } from '@/_types/contact.type';
import { TLivePage } from '@/_types/promo-page/live-page.type';
import { TLivePageAgendaItem, TPatchLivePageParams } from '@/_modules/promo/api/promo-page.api';
import { TUploadFileResponse } from '@/_api/upload/upload.api';
import PromoLiveBroadcasts from '@/_modules/promo/components/promo-live-broadcasts/promo-live-broadcasts.vue';
import _cloneDeep from 'lodash.clonedeep';
import _isEqual from 'lodash.isequal';
import ValidationHelper from '@/_helpers/validation.helper';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;

type TAbstractObject = {
  [key: string]: any;
}

type TStandTexts = {
  [key: string]: TranslateResult;
}

type TLivePageErrors = {
  title: TranslateResult | string;
  descr: TranslateResult | string;
  video_player_frame: TranslateResult | string;
  agenda_video_player_frame: TranslateResult | string;
}

interface ICabinetLobbyData {
  agendaScrollHeight: string;
  isEditingLiveTitle: boolean;
  isEditingLiveDescription: boolean;
  addAgendaItem: TLivePageAgendaItem;
  live: TLivePage;
  noteList: TAbstractObject[];
  errors: TLivePageErrors;
  sponsorUploaderClickFlag: boolean;
  isIntroFileUploading: boolean;
  isAgendaFileUploading: boolean;
  isSponsorFileUploading: boolean;
  isFormStatusShown: boolean;
  formStatusNotificationMode: string;
}

interface ICabinetLobbyMethods {
  clearIntroFile: () => void;
  clearAgendaFile: () => void;
  passClickIntoSponsorPhotoFileInput: (flag: boolean) => void;
  getNotes: () => void;
  toggleEditLiveTitle: (mode?: string) => void;
  toggleEditLiveDescription: (mode?: string) => void;
  savePage: () => Promise<number>;
  saveLivePage: (params: TPatchLivePageParams) => Promise<number>;
  getLivePage: (eventId: number) => Promise<TLivePage>;
  refreshLivePage: () => void;
  focusElementAfterMount: (vueRef: string) => void;
  cancelAll: () => void;
  setError: (errorKey: keyof TLivePageErrors, errorText: TranslateResult | string) => void;
  clearError: (errorKey: keyof TLivePageErrors) => void;
  clearErrors: () => void;
  isLivePageDataValid: () => boolean;
  setAgendaItemEditing: (index: number) => void;
  agendaItemRemove: (index: number) => void;
  unsetAgendaItemEditing: (index: number) => void;
  cancelAgendaItemEditing: (index: number) => void;
  handleIntroFiles: (data: TUploadFileResponse) => void;
  handleAgendaFiles: (data: TUploadFileResponse) => void;
  handleSponsorPhoto: (data: TUploadFileResponse) => void;
  isImageExtension: (url: string) => boolean;
  createAgendaItem: () => void;
  removeSponsorItem: (index: number) => void;
  clearIntroVideoPlayerFrame: () => void;
  clearAgendaVideoPlayerFrame: () => void;
  setIntroPhotoLoading: (value: boolean) => void;
  setAgendaPhotoLoading: (value: boolean) => void;
  setSponsorPhotoLoading: (value: boolean) => void;
  setAgendaScrollHeight: () => void;
  scrollAgendaToBottom: () => void;
  seekTo: (targetRefName: string) => void;
  checkPlayerCodeValidity: (entityName: string) => void;
  callPromoPageList: () => void;
  showStatusNotification: (mode: string) => void;
  hideStatusNotification: (delay?: number) => void;
}

interface ICabinetLobbyComputed {
  event: TEvent;
  contact: TContact;
  promoPage: TPromoPage;
  liveTitle: string;
  liveTitleLength: number;
  isLivePageLoading: boolean;
  livePageData: TLivePage;
  noteListAll: TAbstractObject;
  isAddAgendaItemButtonDisabled: boolean;
  isFormUnsaved: boolean;
  sponsorUploaderClick: boolean;
  isPromoPageListLoading: boolean;
  promoPageList: any[]; // TODO: types, refactor using new promopage store
  standTexts: TStandTexts;
}

const CabinetLobby = Vue.extend<ICabinetLobbyData, ICabinetLobbyMethods, ICabinetLobbyComputed>({
  name: 'cabinet-lobby',
  components: {
    notesList,
    uploader,
    PromoLiveBroadcasts,
  },
  computed: {

    ...mapState({
      noteListAll: state => (state as any).noteStore.noteList,
      isPromoPageListLoading: state => (state as any).promoStore.promoPageListLoading,
      promoPageList: state => (state as any).promoStore.promoPageList,
    }),

    ...mapGetters('_eventStore', {
      event: 'event',
    }),

    ...mapGetters('cabinetLobbyStore', {
      isLivePageLoading: 'isLivePageLoading',
      livePageData: 'livePageData',
    }),

    ...mapGetters('promoPageStore', {
      contact: 'contact',
      promoPage: 'promoPage',
    }),

    liveTitle: {
      get(): string {
        if (this.isEditingLiveTitle) {
          // If the user deleted the text but hasn't yet pressed Save, don't fallback to event.title
          return this.live.title;
        }
        return this.live.title || (this.event ? this.event.title : '');
      },
      set(value: string): void {
        this.live.title = value;
      }
    },

    /* This property is part of the scheme which passes click into
     * an input inside a child component
     */
    sponsorUploaderClick: {
      get(): boolean {
        return this.sponsorUploaderClickFlag;
      },
      set(value: boolean): void {
        this.sponsorUploaderClickFlag = value;
      }
    },

    liveTitleLength: {
      get(): number {
        const extraSizePadding = 7;
        const maxSize = 50;
        return Math.min(maxSize, this.live.title.length + extraSizePadding);
      },
    },

    isAddAgendaItemButtonDisabled: {
      get(): boolean {
        return this.addAgendaItem.data.title.trim() === '';
      }
    },

    isFormUnsaved: {
      get(): boolean {

        const compareWhat = this.live;
        const compareTo = this.livePageData;

        // In case store value is not yet available
        if(!compareTo) {
          return false;
        }

        return (
          (compareWhat.title !== compareTo.title)
          || (compareWhat.descr !== compareTo.descr)
          || (!_isEqual(compareWhat.agenda, compareTo.agenda))
          || (!_isEqual(compareWhat.photos, compareTo.photos))
          || (compareWhat.video_player_frame !== compareTo.video_player_frame)
          || (compareWhat.video_file_url !== compareTo.video_file_url)
          || (compareWhat.agenda_video_player_frame !== compareTo.agenda_video_player_frame)
          || (compareWhat.agenda_video_file_url !== compareTo.agenda_video_file_url)
          || (compareWhat.show_title !== compareTo.show_title)
          || (compareWhat.show_event_program !== compareTo.show_event_program)
          || (compareWhat.show_sponsors !== compareTo.show_sponsors)
          || (compareWhat.show_event_news !== compareTo.show_event_news)
          || (compareWhat.show_streaming !== compareTo.show_streaming)
        );
      }
    },

    standTexts: {
      get(): TStandTexts {
        return {
          inactive: this.$t('organizerCabinet.sections.lobby.standBroadcastInactive'),
          checking: this.$t('organizerCabinet.sections.lobby.standBroadcastChecking'),
        };
      },
    },

  },
  watch: {
    event: {
      handler(): void {
        if (this.event && this.event.id) {
          this.getLivePage(this.event.id);
          this.getNotes();
        }
      }
    },
    livePageData: {
      deep: true,
      immediate: true,
      handler(): void {
        if (this.livePageData) {
          this.live = _cloneDeep(this.livePageData);
          this.liveTitle = this.livePageData.title;
        }
      }
    },
  },
  created() {
    this.callPromoPageList();
  },
  mounted() {
    /* Handlers were not enough. */
    if (this.event && this.event.id) {
      this.getLivePage(this.event.id);
      this.getNotes();
      this.callPromoPageList();
    }

    this.$nextTick(() => {
      this.setAgendaScrollHeight();
      this.scrollAgendaToBottom();
    });

    window.addEventListener('resize', this.setAgendaScrollHeight);

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setAgendaScrollHeight);
  },
  data(): ICabinetLobbyData {
    return {
      agendaScrollHeight: '300px',
      sponsorUploaderClickFlag: false,
      isEditingLiveTitle: false,
      isEditingLiveDescription: false,
      isFormStatusShown: false,
      formStatusNotificationMode: 'default',
      addAgendaItem: {
        isEditing: false,
        toDelete: false,
        data: {
          title: ''
        },
      },
      live: {
        title: '',
        descr: '',
        video_player_frame: '',
        video_file_url: '',
        agenda: [],
        agenda_video_player_frame: '',
        agenda_video_file_url: '',
        photos: [],
        show_title: true,
        show_event_program: true,
        show_sponsors: true,
        show_event_news: true,
        show_streaming: true,
      },
      errors: {
        title: '',
        descr: '',
        video_player_frame: '',
        agenda_video_player_frame: '',
      },
      noteList: [],
      isIntroFileUploading: false,
      isAgendaFileUploading: false,
      isSponsorFileUploading: false,
    };
  },
  methods: {

    ...mapActions('cabinetLobbyStore', {
      refreshLivePage: 'refresh',
      getLivePage: 'getLivePage',
      saveLivePage: 'saveLivePage',
    }),

    /* Do we see or do we edit the live page title?
     * Toggles the corresponding flag.
     */
    toggleEditLiveTitle(mode?: string): void {
      this.isEditingLiveTitle = !this.isEditingLiveTitle;
      if (mode === 'cancel') {
        this.live.title = this.livePageData.title;
      } else {
        this.focusElementAfterMount('editLiveTitleInput');
      }
    },

    /* Do we see or do we edit the live page description?
     * Toggles the corresponding flag.
     */
    toggleEditLiveDescription(mode?: string): void {
      this.isEditingLiveDescription = !this.isEditingLiveDescription;
      if (mode === 'cancel') {
        this.live.descr = this.livePageData.descr;
      } else {
        this.focusElementAfterMount('editLiveDescrInput');
      }
    },

    /* Validation and error display
     */
    isLivePageDataValid(): boolean {
      this.clearErrors();
      let errorCount = 0;

      if (this.live.video_player_frame && ValidationHelper.isValidVideoStreamEmbed(this.live.video_player_frame) === false) {
        this.errors.video_player_frame = this.$t('organizerCabinet.sections.lobby.fieldErrors.playerFrameCodeInvalid');
        errorCount++;
      }
      if (this.live.agenda_video_player_frame && ValidationHelper.isValidVideoStreamEmbed(this.live.agenda_video_player_frame) === false) {
        this.errors.agenda_video_player_frame = this.$t('organizerCabinet.sections.lobby.fieldErrors.playerFrameCodeInvalid');
        errorCount++;
      }

      return errorCount === 0;
    },

    /* Passes the whole live page data to the action
     * since we do not have separate routes for saving pieces of the page
     */
    async savePage(): Promise<number> {

      this.hideStatusNotification();

      if (!this.isLivePageDataValid()) {
        return -1; // savePage() has to return something of proper type. TypeScript said so.
      }

      // N.B.!!! Agenda gets mapped into strings inside PATCH api request until we settle down with how our agenda works
      // Filtering items marked toDelete = false
      this.live.agenda = this.live.agenda.filter((x: TLivePageAgendaItem) => !x.toDelete);

      const result = await this.saveLivePage({
        eventId: this.event.id,
        livePageData: this.live
      });

      // N.B. returned response.status for honest success visual feedback
      if (result === 202) {
        this.showStatusNotification('success');
        this.hideStatusNotification(4321); // milliseconds
      } else {
        this.showStatusNotification('error');
        this.hideStatusNotification(4321); // milliseconds
      }

      // Have to re-request GET event/live so that livePageData getter value changes
      // Can be dispatched from inside the actions.saveLivePage, but here seems better
      await this.refreshLivePage();

      this.isEditingLiveDescription = false;
      this.isEditingLiveTitle = false;

      return result;
    },

    /* One cancel for them all
     * Closes all inputs being edited
     * Shows content that was here before the unfinished editing
     */
    cancelAll(): void {
      this.clearErrors();

      if (this.isEditingLiveTitle ) {
        this.toggleEditLiveTitle('cancel');
      }

      if (this.isEditingLiveDescription ) {
        this.toggleEditLiveDescription('cancel');
      }

      this.live = _cloneDeep(this.livePageData);

      // These two lines trigger video preview image change
      this.handleIntroFiles({filename: '', url: this.live.video_file_url, size: -1, type: 'video/dummy'});
      this.handleAgendaFiles({filename: '', url: this.live.agenda_video_file_url, size: -1, type: 'video/dummy'});

    },

    /* Gets the list of event creator's notes
     * for the current event
     */
    getNotes(): void {
      this.$store.dispatch('noteStore/callNoteListAll', {
        event_id: this.event.id,
        user_id: this.event.creator_user_id
      }).then(() => {
        this.noteList = this.noteListAll.List;
      });
    },

    /* Gets the list of event creator's notes
     * for the current event
     */
    focusElementAfterMount(vueRef: string): void {
      this.$nextTick(() => {
        if (this.$refs[vueRef]) {
          (this.$refs[vueRef] as HTMLFormElement).focus();
        }
      });
    },

    /* Sets the error for the field with the given key (@param errorKey)
     */
    setError(errorKey: keyof TLivePageErrors, errorText: TranslateResult | string): void {
      this.errors[errorKey] = errorText;
    },

    /* Clears the given error
     */
    clearError(errorKey: keyof TLivePageErrors): void {
      this.setError(errorKey, '');
    },

    /* Clears all form errors
     */
    clearErrors(): void {
      for (const errorKey in this.errors) {
        this.setError((errorKey as keyof TLivePageErrors), '');
      }
    },

    /* Marks some array item as being edited
     * the Vue reactive way
     */
    setAgendaItemEditing(index: number): void {
      const newValue = {
        ...this.live.agenda[index]
      };
      newValue.isEditing = true;
      Vue.set(this.live.agenda, index, newValue);
    },

    /* Sets isEditing to false
     * the Vue reactive way
     */
    unsetAgendaItemEditing(index: number): void {
      const newValue = {
        ...this.live.agenda[index]
      };
      newValue.isEditing = false;
      Vue.set(this.live.agenda, index, newValue);
    },

    /* Cancel click handler
     */
    cancelAgendaItemEditing(index: number): void {
      this.unsetAgendaItemEditing(index);
      const newValue = {
        ..._cloneDeep(this.live.agenda[index])
      };
      newValue.isEditing = false;
      newValue.toDelete = false;
      Vue.set(this.live.agenda, index, newValue);
    },

    /* Marks an item for deletion upon save,
     * mutates the array the vue-reactive way
     * for visual change in the UI
     */
    agendaItemRemove(index: number): void {
      const processedItem: TLivePageAgendaItem = {
        ..._cloneDeep(this.live.agenda[index])
      };
      processedItem.toDelete = true;
      Vue.set(this.live.agenda, index, processedItem);
    },

    /* Handler for uploads in intro block
     */
    handleIntroFiles(data: TUploadFileResponse): void {
      // I need to clear the video element
      // And insert it again so that new loadeddata event shall fire
      // Otherwise video preview image wont change
      this.live.video_file_url = '';
      this.$nextTick(() => {
        this.live.video_file_url = data.url;
      });
    },

    /* Handler for uploads in agenda block
     */
    handleAgendaFiles(data: TUploadFileResponse): void {
      // I need to clear the video element
      // And insert it again so that new loadeddata event shall fire
      // Otherwise video preview image wont change
      this.live.agenda_video_file_url = '';
      this.$nextTick(() => {
        this.live.agenda_video_file_url = data.url;
      });
    },

    /* Naive: is it an image?
     */
    isImageExtension(url: string): boolean {
      if (!url) return false;
      const whiteList = {
        jpg: true,
        png: true,
        gif: true,
        jpe: true,
        jpeg: true,
        svg: true
      };

      const urlParts: string[] = url.split('.');
      const ext = urlParts.pop().toLowerCase();
      return Object.prototype.hasOwnProperty.call(whiteList, ext);

    },

    /* Pushes the new agenda item into both live.agenda and tempLiveAgenda
     * Clears addAgendaItem
     */
    createAgendaItem(): void {
      if (this.isAddAgendaItemButtonDisabled) {
        return;
      }

      this.live.agenda.push(_cloneDeep(this.addAgendaItem));

      this.addAgendaItem.isEditing = false;
      this.addAgendaItem.toDelete = false;
      this.addAgendaItem.data.title = '';

      this.$nextTick(() => {
        this.scrollAgendaToBottom();
      });
    },

    /* handles the upload of sponsor photo
     */
    handleSponsorPhoto(data: TUploadFileResponse): void {
      this.live.photos.push(data.url);
    },

    /* Trigger a method inside a ref-ed component... Sorry, TypeScript'o
     */
    passClickIntoSponsorPhotoFileInput(flag: boolean): void {
      this.sponsorUploaderClick = flag; // This will be passed into a prop of sponsor photo uploader
    },

    /* Removes an item from photos reactively.
     */
    removeSponsorItem(index: number): void {
      this.live.photos.splice(index, 1);
    },

    /* Sets video_player_frame = ''
     */
    clearIntroVideoPlayerFrame(): void {
      this.live.video_player_frame = '';
      this.clearError('video_player_frame');
    },

    /* Sets agenda_video_player_frame = ''
     */
    clearAgendaVideoPlayerFrame(): void {
      this.live.agenda_video_player_frame = '';
      this.clearError('agenda_video_player_frame');
    },

    setIntroPhotoLoading(value: boolean): void {
      this.isIntroFileUploading = value;
    },

    setAgendaPhotoLoading(value: boolean): void {
      this.isAgendaFileUploading = value;
    },

    setSponsorPhotoLoading(value: boolean): void {
      this.isSponsorFileUploading = value;
    },

    /* Sets .video_file_url = '';
     */
    clearIntroFile(): void {
      this.live.video_file_url = '';
    },

    /* Sets tempLiveAgendaVideoFileUrl = '';
     */
    clearAgendaFile(): void {
      this.live.agenda_video_file_url = '';
    },

    /* Perfect scrollbar needs some height to be set
     */
    setAgendaScrollHeight(): void {
      let result = '0';
      const agendaPhotoArea = this.$refs.agendaPhotoArea;
      const heightPercentageOfWidth = 52.5; // Proportion is taken from design

      if (!agendaPhotoArea) {
        this.agendaScrollHeight = result;
      }

      let computedWidth: number = (agendaPhotoArea as HTMLElement).getBoundingClientRect().width;
      computedWidth = computedWidth <= 0 ? 0 : Math.floor(computedWidth) / 100 * heightPercentageOfWidth;

      if (computedWidth > 0) {
        result = computedWidth.toString() + 'px';
      }

      this.agendaScrollHeight = result;
    },

    /* Scrolls the perfect scrollbar to bottom
     */
    scrollAgendaToBottom(): void {
      const psElement: HTMLElement = ((this.$refs.agendaPerfectScrollbar as Vue).$el as HTMLElement);

      if (!psElement) {
        return;
      }

      psElement.scrollTop = psElement.scrollHeight;
    },

    /* For video previews, try seek to around PERCENTAGE% of video duration
     * in hopes that the frame at PERCENTAGE% is not solid color
     * Solid color previews look like there is nothing.
     * First frame of a video is often solid black or other color.
     */
    seekTo(targetRefName: string): void {
      const PERCENTAGE = 0.25;

      if (this.$refs[targetRefName]) {
        try {
          const vid = (this.$refs[targetRefName] as HTMLVideoElement);
          vid.currentTime = vid.duration * PERCENTAGE;
        } catch (e) {
        }
      }

    },

    checkPlayerCodeValidity(entityName: string): void {
      this.clearError(entityName as keyof TLivePageErrors);
      let errorText: TranslateResult = '';
      switch (entityName) {
        case 'video_player_frame':
          if (this.live.video_player_frame && ValidationHelper.isValidVideoStreamEmbed(this.live.video_player_frame) === false) {
            errorText = this.$t('organizerCabinet.sections.lobby.fieldErrors.playerFrameCodeInvalid');
          }
          break;
        case 'agenda_video_player_frame':
          if (this.live.agenda_video_player_frame && ValidationHelper.isValidVideoStreamEmbed(this.live.agenda_video_player_frame) === false) {
            errorText = this.$t('organizerCabinet.sections.lobby.fieldErrors.playerFrameCodeInvalid');
          }
          break;
        default:
          return;
      }

      this.setError(entityName as keyof TLivePageErrors, errorText);
    },

    callPromoPageList(): void {
      this.$store.dispatch('promoStore/promoPageListAll', { event_id: this.$route.params.eventId });
    },

    showStatusNotification(mode: string): void {
      this.formStatusNotificationMode = mode;
      this.isFormStatusShown = true;
    },

    hideStatusNotification(delay: number): void {
      if(typeof delay !== 'undefined') {
        setTimeout(() => {
          this.isFormStatusShown = false;
        }, delay);
      } else {
        this.isFormStatusShown = false;
      }
    },
  }
});
export default CabinetLobby;
